/*  footer
    ========================================================================== */
    .footer {
        font-size: 15px;
        .footer-top {
            padding: 6vh 0;
            background-color: transparent;
            text-align: center;
            .footer_list {
                h5 {
                    font-size: 17px;
                    font-weight: 700;
                }
                .list {
                    .list-item {
                        margin: 5px 0;
                        .link {
                            color: $black;
							text-decoration: none;
                        }
                    }
                }
            }
        }
        .footer-middle {
            position: relative;
            margin-top: 95px;
            padding: 110px 0 6vh 0;
            background-color: $green-lighter;
            .logo {
                position: absolute;
                top: -95px;
                left: 0;
                right: 0;
                width: 160px;
                margin: 0 auto;
				box-shadow: 6px 6px 10px rgba($black, 0.15);
            }
            .contact-info {
                flex-direction: column;
                align-items: center;
                li {
                    margin: 0 0 5px 0;
                    font-size: 13px;
                    font-weight: 900;
                    letter-spacing: 5px;
                    text-transform: uppercase;
                    a {
						color: $black;
                        text-decoration: none;
                        i {
							color: $purple;
							font-size: 19px;
						}
                        &:hover {
                            color: $purple;
                        }
                    }
                }
            }
            .footer_text {
                text-align: center;
            }
            .social {
                justify-content: center;
                li {
                    margin: 0 0 0 15px;
                    font-size: 26px;
                    a {
                        color: $purple;
                        &:hover {
                            color: $green;
                        }
                    }
                }
            }

            .container-three-columns {
                @include media-breakpoint-up(md) {
                    .column.one {
                        flex: 0 0 25%;
                        max-width: 25%;
                    }

                    .column.two {
                        flex: 0 0 50%;
                        max-width: 50%;
                    }

                    .column.three {
                        flex: 0 0 25%;
                        max-width: 25%;
                    }
                }
            }

        }
        .footer-bottom {
            padding: 25px 0;
            background-color: $green-dark;
            color: $white;
            text-align: center;
            .footer_link {
                .list {
                    flex-direction: column;
                    justify-content: center;
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    @include media-breakpoint-up(md) {
                        flex-direction: row;
                    }
                    .list-item {
                        margin: 5px 0;
                        color: $white;
                        font-weight: 400;
                        .link {
                            color: $white;
							text-decoration: none;
                        }
                    }
                    @include media-breakpoint-up(md) {
                        .list-item + li::before  {
                            content: "|";
                            margin: 0 15px;
                        }
                    }
                }
            }
        }
    }

	// whatsapp
	.whatsapp {
		margin: 0;
		li a {
			position: fixed;
			width: 70px;
			height: 70px;
			bottom: 123px;
			background-image: url("/images/whatsapp.png");
			background-size: 70px;
			right: 15px;
			color: #fff;
			text-align: center;
			font-size: 30px;
			z-index: 100;
			text-indent: -9999px;
		}
	}
