&.accommodation_category_detail,
&.accommodation_detail {
	.accommodation_detail {
		.info {
			&.full-width {
				@include make-col-ready();
				@include make-col(12);
			}
		}

		.tommy-info {
			@include make-col-ready();
			@include make-col(12);
		}
	}
}

&.accommodation_book {
	#sleak-container {
		display: none;
	}
}