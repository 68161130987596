/*  main
    ========================================================================== */
	.main {
		position: relative;
		&::before {
			content: "";
			z-index: -1;
			position: fixed;
			top: 10%;
			left: 0;
			width: 100%;
			height: 100%;
			max-width: 740px;
			max-height: 778px;
			background: url('/images/logo-bg-visual.svg') no-repeat;
			background-size: contain;
		}
	}

/*  intro
    ========================================================================== */
    .intro {
        text-align: center;
        .container-holder {
            max-width: 750px;
            margin: 0 auto;
        }
        p:first-of-type {
            position: relative;
            margin-top: 25px;
        }
        p:first-of-type::first-letter {
            color: $purple;
            font-size: 48px;
            font-weight: 400;
            line-height: 1;
            font-family: $font-family-playball;
			@include media-breakpoint-up(xl) {
				font-size: 60px;
			}
        }
        @include media-breakpoint-up(md) {
            p:first-of-type::before {
                display: none;
                content: "";
                z-index: -1;
                position: absolute;
                top: -10px;
                left: -15px;
                width: 65px;
                height: 65px;
                background-color: $green-light;
            }
        }
    }

/*  content
    ========================================================================== */
    .content-padding {
        padding: $content-padding;
    }

/*  usps
    ========================================================================== */
    .usps {
        margin: 30px 0;
        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                display: flex;
                align-items: center;
                margin: 10px 30px;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                i {
                    margin-right: 10px;
                    color: $green-light;
                    font-size: 21px;
                }
				a {
					color: $black;
					text-decoration: none;
				}
            }
        }
    }


/*  accommodations
    ========================================================================== */
    .accommodations {
        margin-top: 6vh;
        .container {
            max-width: 1920px;
            margin-left: auto !important;
            margin-right: auto !important;
            padding: 0;
        }
    }


/*  home-blocks
    ========================================================================== */
    .home-blocks {
        .intro-text {
            max-width: 640px;
            margin-bottom: 6vh;
            p:first-of-type::first-letter {
                color: $purple;
                font-size: 48px;
                font-weight: 400;
                line-height: 1;
                font-family: $font-family-playball;
            }
        }
    }
