.eyecatcher {
	overflow: hidden;

	.container-fluid { max-width: unset;}
	.owl-carousel {
		.item {
			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: rgba($color: #000000, $alpha: 0.2);
			}
			.owl-caption {
				z-index: 100;
				position: relative;
                color: $white;
				text-align: center;
				.owl-title {
					margin-bottom: 0;
					font-size: calc(1.75rem + 1.5vw);
					font-weight: 400;
					text-shadow: none;
					font-family: $font-family-playball;
					@include media-breakpoint-up(xl) {
                        font-size: 80px;
					}
				}
				.owl-subtitle {
					margin-bottom: 0;
					font-size: calc(1.25rem + 0.5vw);
					font-weight: 500;
					text-shadow: none;
					@include media-breakpoint-up(xl) {
                        font-size: 36px;
					}
				}
			}
		}
	}

	// video iframe
	iframe {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100vw;
		height: 100vh;
		max-width: none;
		transform: translate(-50%, -50%);

		@media (min-aspect-ratio: 16/9) {
			/* height = 100 * (9 / 16) = 56.25 */
			height: 56.25vw;
		}

		@media (max-aspect-ratio: 16/9) {
			/* width = 100 / (9 / 16) = 177.777777 */
			width: 177.78vh;
		}
	}

	// non home
	&:not(.large) {
		@include media-breakpoint-up(xl) {
			min-height: 450px;

			.owl-carousel {
				min-height: 450px;

				.item {
					min-height: 450px;
				}
			}
		}
	}

}
