/*  default
    ========================================================================== */
    .card {
		border-color: #d5d5d5;
        .card-body {
            .card-caption {
                .card-description {
                    &:after {
                        display: none !important;
                    }
                }
            }
            .card-buttons {
                .card-btn {
					display: flex;
					align-items: center;
					padding: 0;
					background: none;
					border: none;
					color: $purple;
					font-size: 16px;
					font-weight: 700;
					text-transform: none;
					letter-spacing: 0;
					&::after {
						content: "\f061";
						margin-left: 10px;
						font-size: 20px;
						font-weight: 900;
						font-family: "Font Awesome 5 Pro";
						transition: margin 0.5s;
					}
					&:hover {
						color: $purple;
						&::after {
							margin-left: 15px;
						}
					}
                }
            }
        }
    }


/*  home-blocks
    ========================================================================== */
    .home-blocks {
		margin: 6vh 0;
		padding: 0 !important;
		h2 {
			font-size: 36px;
			font-weight: 400;
			@include media-breakpoint-up(xl) {
				font-size: 50px;
			}
			font-family: $font-family-playball;
		}
        .card {
            .card-body {
                .card-caption {
                    .card-subtitle {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        margin-bottom: 0;
                        padding: 11px 25px;
                        background-color: $purple;
                        color: $white;
                    }
                }
            }
        }
    }


/*  accommodations
    ========================================================================== */
    .accommodations {
        .container-default { margin: 6vh 0;}
        .grid {
            position: relative;
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            @include media-breakpoint-up(xl) {
                flex-direction: column;
                height: 100vh;
                max-height: 600px;
            }
            @include media-breakpoint-up(xl) {
                &.group-1 {
					margin-right: 30px;
                    .item:nth-child(1) {
                        order: 3;
						.card {
							border-radius: 0 10px 10px 0;
						}
                    }
                    .item:nth-child(2) {
                        order: 1;
                        flex-grow: 1;
                    }
                    .item:nth-child(3) {
                        order: 2;
                        flex-grow: 1;
                    }
                }

                &.group-2 {
					margin-left: 30px;
					.item:nth-child(1) {
						.card {
							border-radius: 10px 0 0 10px;
						}
					}
                    .item:nth-child(2) {
                        flex-grow: 1;
                    }
                    .item:nth-child(3) {
                        flex-grow: 1;
                    }
                }

            }
            .item {
                margin: 0;
                padding: 0;
                .card {
                    border: none;
					border-radius: 0;
                    box-shadow: none;
                    .card-buttons {
                        margin-top: 15px;
                    }
                }
            }

            /* item 1 */
            .item:nth-child(1) {
                min-height: 100%;
                @include make-col(12);
                @include media-breakpoint-up(xl) {
                    @include make-col(8);
                }
                .card {
                    @include media-breakpoint-up(xl) {
                        .card-image {
                            position: absolute !important;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            .card-img {
                                min-width: 100%;
                                min-height: 100%;
                            }
                        }
                    }
                    .card-body {
                        z-index: 100;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        padding: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        background-color: rgba($color: #000000, $alpha: 0.15);
                        .card-caption {
                            max-width: 500px;
                            margin: 0 auto;
                            .card-title {
                                color: $white;
                                font-size: 36px;
								font-weight: 400;
                                text-align: center;
								font-family: $font-family-playball;
								@include media-breakpoint-up(xl) {
									font-size: 50px;
								}
                            }
                            .card-subtitle { display: none;}
                            .card-description { display: none;}
                        }
                        .card-buttons { display: none;}
                    }
                }
            }

            /* item-2 & item-3 */
            .item:nth-child(2),
            .item:nth-child(3) {
                @include make-col(12);
                @include media-breakpoint-up(lg) {
                    @include make-col(6);
                }
                @include media-breakpoint-up(xl) {
                    height: 50%;
                    @include make-col(4);
                }
                .card {
                    .card-image { display: none;}
                    .card-body {
                        justify-content: center;
                        @include media-breakpoint-down(lg) {
                            margin: 0 auto;
                        }
                        padding: 30px;
                        .card-caption {
                            .card-title {
                                margin-bottom: 0;
                                font-size: 22px;
                                @include media-breakpoint-up(xl) {
                                    font-size: 24px;
                                }
                                @include media-breakpoint-down(lg) {
                                    text-align: center;
                                }
								&:first-of-type::first-letter {
									color: $purple;
									font-size: 48px;
									font-weight: 400;
									line-height: 1;
									font-family: $font-family-playball;
									@include media-breakpoint-up(xl) {
										font-size: 60px;
									}
								}
                            }
                            .card-description {
                                @include media-breakpoint-down(lg) {
                                    text-align: center;
                                }
                            }
                        }
                        .card-buttons {
                            @include media-breakpoint-down(lg) {
                                text-align: center;
                            }
                            .card-btn {
								display: flex;
								align-items: center;
								padding: 0;
								background: none;
                                border: none;
								font-size: 16px;
								font-weight: 700;
								text-transform: none;
								letter-spacing: 0;
								&::after {
									content: "\f061";
									margin-left: 10px;
									font-size: 20px;
									font-weight: 900;
									font-family: "Font Awesome 5 Pro";
									transition: margin 0.5s;
								}
                                &:hover {
									&::after {
										margin-left: 15px;
									}
                                }
                            }
                        }
                    }
                }
            }
            .item:nth-child(2) {
				.card, .card-body { background: $green-light;}
			}
            .item:nth-child(3) {
				.card, .card-body { background: $green-lighter;}

			}
        }
    }
