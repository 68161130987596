.header {
	z-index: 100;

	@include media-breakpoint-down(lg) {
		background: $green-lighter;
	}

	// header-top
	.header-top {
		.container-navbar {
			padding-top: 15px;
			padding-bottom: 15px;
			@include media-breakpoint-up(xl) {
				padding: 20px 35px;
			}
			.navbar {
				padding: 0;
				justify-content: flex-end;
			}
		}

		// quick-contact
		.quick-contact {
			margin: 0 5px 0 0;
			padding: 0;
			list-style: none;
			li {
				margin: 0 0 0 15px;
				font-size: 16px;
				font-weight: 700;
				line-height: 1;
				a {
					display: block;
					padding: 0;
					color: $white;
					text-decoration: none;
					&:hover {
						color: $purple;
					}
				}
			}
		}

		// language
		.language {
			flex-direction: row;
			.nav-item {
				margin-left: 10px;
				.nav-link {
					padding: 0;
					img {
						display: block;
						width: 21px;
						height: 21px;
						border-radius: 100%;
						transition: opacity 0.5s;
						overflow: hidden;
					}
					&:hover {
						img {
							opacity: 0.6;
						}
					}
				}
			}
		}
	}

	// header-main
	.header-main {
		background: transparent;
		padding-bottom: 15px;

		.container-navbar {
			padding: 0 15px !important;
			@include media-breakpoint-up(xl) {
				padding: 0 35px !important;
			}
			.navbar {
				padding: 0;
			}
		}

		// logo
		.logo {
			transition: 0.5s;
			box-shadow: 6px 6px 10px rgba($black, 0.15);
			@include media-breakpoint-down(lg) {
				max-width: 90px;
				margin-top: -36px;
			}
			@include media-breakpoint-up(xl) {
				max-width: 160px;
				margin: 0 30px -100px 0;
			}
		}

		// navbar-toggler
		.navbar-toggler {
			z-index: 990;
			position: relative;
			display: flex;
			align-items: center;
			height: 40px;
			margin: auto auto 0 15px;
			padding: 0 10px;
			border: none;
			border-radius: 5px;
			background-color: $green-light;
			color: $white;
			font-size: 16px;
			font-weight: 700;
			line-height: 40px;
			text-align: center;
			outline: none;
			transition: background-color 0.5s, color 0.5s;
			.navbar-toggler-icon {
				margin-right: 5px;
				font-size: 20px;
			}
			&:hover {
				background-color: $green;
				color: $white;
			}
		}

		// menu
		.menu {
			@include media-breakpoint-down(lg) {
				order: 3;
				margin-top: 15px;
			}
			transition: 0.5s;
			align-items: flex-start;
			.navbar-nav {
				margin: 0;
				.nav-item {
					position: relative;
					@include media-breakpoint-up(xl) {
						margin-right: 25px;
					}
					font-size: 16px;
					font-weight: 700;
					white-space: nowrap;
					.nav-link {
						padding: 0;
						color: $white;
						line-height: 30px;
						text-decoration: none;
						&:hover {
							color: $white;
							@include media-breakpoint-down(lg) {
								color: $purple;
							}
						}
					}
					&.active .nav-link {
						color: $white;
						@include media-breakpoint-down(lg) {
							color: $purple;
						}
					}
					@include media-breakpoint-up(xl) {
						&::after {
							content: "";
							position: absolute;
							top: 100%;
							width: 0%;
							height: 2px;
							background-color: $white;
							transition: 0.5s;
						}
						&:hover {
							&::after {
								width: 100%;
							}
						}
						&.active {
							&::after {
								width: 100%;
							}
						}
					}
					.dropdown-menu {
						@include media-breakpoint-down(lg) {
							margin-top: 0;
							border: none;
							background: transparent;
						}
						@include media-breakpoint-up(xl) {
							border-color: $white;
							box-shadow: 0 3px 6px rgba($black, 0.15);
						}
						.nav-item {
							text-transform: none;
							font-size: 15px;
							.nav-link {
								padding: 0 15px;
								color: $black;
								font-weight: 400;
								@include media-breakpoint-down(lg) {
									color: $white;
								}
								&:hover {
									color: $purple;
								}
							}
							&.active .nav-link {
								color: $purple;
							}
						}
					}
				}
			}
		}

		// book-button
		.book-button {
			margin: auto 0 0 auto;
			li {
				margin-right: 0;

				a {
					display: block;
					height: 40px;
					padding: 0 10px;
					border-radius: 5px;
					@include media-breakpoint-up(xl) {
						height: 60px;
						padding: 0 25px;
						line-height: 60px;
					}
					font-size: 16px;
					font-weight: 700;
					line-height: 40px;
					text-transform: none;
					background: $purple;
					border-color: $purple;
					color: $white;
					text-decoration: none;
					&:hover {
						background: $green;
						border-color: $green;
					}
				}
			}
		}

		// quick-menu
		.quick-menu {
			display: none !important;
			@include media-breakpoint-up(lg) {
				display: flex !important;
			}
			position: absolute;
			top: 75px;
			left: 300px;
			@include media-breakpoint-up(xl) {
				left: 330px;
			}
			margin: 0;
			padding: 0;
			list-style: none;
			transition: 0.5s;
			li {
				margin: 0 15px 0 0;
				@include media-breakpoint-up(xl) {
					margin: 0 30px 0 0;
				}
				color: $white;
				font-size: 16px;
				font-weight: 700;
				line-height: 50px;
				text-transform: uppercase;
				a {
					display: block;
					color: $white;
					text-decoration: none;
					&:hover {
						color: $green;
					}
					i {
						display: none;
					}
				}
			}
		}
	}

	/*  sticky
    ========================================================================== */
	@include media-breakpoint-up(xl) {
		&.sticky {
			background: rgba($green-light, 0.9);

			.header-main {
				.logo {
					max-width: 100px;
					margin-bottom: -40px;
				}
				.quick-menu {
					top: 0;
					left: 130px;
					li {
						a {
							color: $green;
							&:hover {
								color: $purple;
							}
						}
					}
				}
			}
		}
	}

}
