.btn {
    padding: 0.95rem 30px;
    border-radius: 5px;
    font-size: 11px;
    font-weight: 900;
    letter-spacing: 4px;
    text-decoration: none;
    text-transform: uppercase;
}

.btn-back {
    &::before {
        display:inline-block;
        content: '\f0a8';
        font-weight: 300;
        margin-right: 3px;
    }
}