@include media-breakpoint-down(md) {
	.mini-sab {
		display: none;
	}
}
&.home .mini-sab {
	display: block;
}

.mini-sab {
	z-index: 998;
	padding: 0;
	background: $green-lightest;
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;
		.container-holder {
			z-index: 100;
			position: relative;
			padding: 15px;
			border-radius: 10px;
			background: $green-lighter;
			@include media-breakpoint-down(lg) {
				margin-top: -60px;
			}
			@include media-breakpoint-up(xl) {
				position: absolute;
				left: auto;
				right: auto;
				bottom: 30px;
				padding: 15px 30px;
			}
			p {
				margin-bottom: 15px;
				font-size: 30px;
				font-weight: 400;
				line-height: 1;
				font-family: $font-family-playball;
			}
		}
	}
}
