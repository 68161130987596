/*  bootstrap > colors
    ========================================================================== */
    $black:         #000000;
    $green:         #006837;
	$green-light: 	#8bac7d;
	$green-lighter: #c8d8c1;
    $green-lightest:#eef2ea;
	$green-dark:	#125536;
	$purple:		#8261b4;

/*  bootstrap > theme-colors
    ========================================================================== */
    $primary:    	$green;
    $secondary:     $purple;

    $dark:          $green-lighter;

/*  bootstrap > body
    ========================================================================== */
    $body-bg:       	$green-lightest;
    $body-color:        $black;

    $link-color:        $green;
	$link-decoration: 	underline;
	$link-hover-color: 	darken($link-color, 50%);

/*  bootstrap > font
    ========================================================================== */
	// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;900&family=Playball&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Playball&display=swap');

    $font-family-Outfit: "Outfit", sans-serif;
    $font-family-playball: 'Playball', cursive;
    $font-family-base: $font-family-Outfit;

/*  bootstrap > typography
    ========================================================================== */
    $font-weight-base: 400;
    $font-weight-bold: 700;

    $headings-font-weight: 700;

/*  misc
    ========================================================================== */

    /* transition */
    a,
    .btn,
    .owl-btn,
    .card-btn { transition: 0.5s !important;}

/*  owl-carousel
    ========================================================================== */
    $carousel-nav-button-width: 50px;
    $carousel-nav-button-font_size: 1.5rem;
